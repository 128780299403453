import {
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";  // Import the reCAPTCHA component
// import { API } from "../services/API";
import { updateAuth } from "../store/Reducers/authReducer";
import { useReduxStore } from "../hooks/useReduxStore";
import * as PaymentService from "../services/paymentService";

const CheckoutForm = ({ amount = 10, id, handelModalType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { dispatch, getState } = useReduxStore();
  const { userData } = getState("AUTH");
  const { email, name } = userData || {};
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(null);
  
  const disabled = isEmpty || errorMessage?.length || !stripe || !recaptchaToken;
  
  const onChangeCard = (obj) => {
    setErrorMessage(obj?.error?.message);
    setIsEmpty(obj?.empty);
  };

  const handleRecaptcha = async (token) => {
    setRecaptchaToken(token);
    setRecaptchaError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!recaptchaToken) {
      setRecaptchaError("Please complete the CAPTCHA");
      return;
    }

    try {
      if (elements == null) {
        return;
      }

      // Trigger form validation and wallet collection
      let elementError = await elements.submit();
      const { error: submitError } = elementError;
      if (disabled) {
        return;
      }
      handelModalType("loading");
      dispatch(updateAuth({ loading: true }));
      let { ok, data, originalError } = await PaymentService.createIntent({
        amount,
        name,
        email,
        recaptchaToken  // Send reCAPTCHA token to your backend
      });
      const { intent, amount: intentAmount } = data || {};

      if (ok && intent && !submitError) {
        const { error } = await stripe.confirmCardPayment(intent, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });

        if (error) {
          handelModalType("failed");
          setErrorMessage(error.message);
        } else {
          let { ok, data, originalError } = await PaymentService.paymentSuccess(
            {
              product_id: id,
              payment_intent: intent,
              intent_amount: intentAmount,
            }
          );
          if (ok) {
            handelModalType("success");
          } 
        }
      } 
      else {
        handelModalType("failed");
      }
    } catch (error) {
      handelModalType("failed");
    } finally {
      dispatch(updateAuth({ loading: false }));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <CardElement onChange={onChangeCard} />
      {errorMessage && (
        <div className="">
          <p className="my-4 text-xs text-red-700 font-medium">
            {errorMessage}
          </p>
        </div>
      )}
      {recaptchaError && (
        <div className="">
          <p className="my-4 text-xs text-red-700 font-medium">
            {recaptchaError}
          </p>
        </div>
      )}
      <ReCAPTCHA
      className={`mt-8`}
        sitekey="6Le84TsqAAAAACaoQneF1ELV6M8LtgExgrcHGdPl"  // Replace with your site key
        onChange={handleRecaptcha}
      />
      <button
        disabled={disabled}
        className={`block mt-8 w-full mx-auto ${
          disabled ? "bg-orange-200" : "bg-secondary"
        } hover:bg-orange-200 text-white rounded-lg px-3 py-2 font-semibold`}
      >
        PAY NOW
      </button>
    </form>
  );
};

export default CheckoutForm;
